<!-- eslint-disable vue/valid-v-model -->
<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="40"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="container-lott">
      <div class="box-search">
        <div class="box-search__title my-1">
          <!-- <h1 class="title">
            ค้นหาเลขเด็ด
          </h1> -->

          <h3 class="text-primary">
            งวดประจำวันที่ {{ roundth ? roundth : "-" }}
          </h3>
        </div>
        <div class="d-flex justify-content-center">
          <otp-input
            v-if="renderComponent"
            ref="otpInput"
            v-model="input_numlot"
            :digits="6"
          >
            <template #errorMessage>
              There is an error
            </template>
          </otp-input>
        </div>

        <div class="row mt-1 pl-1 pr-1">
          <div class="col-4 p-25">
            <button
              type="button"
              class="btn btn-delete w-100"
              @click="cleartext()"
            >
              ลบตัวเลข
            </button>
          </div>
          <div class="col-8 p-25">
            <button
              type="button"
              class="btn btn-search w-100"
              @click="Search()"
            >
              ค้นหาลอตเตอรี่
              <img
                src="@/assets/images/newIcon/ic_menu/searchLot.png"
                class="ml-n50"
                height="30"
              >
            </button>
          </div>
        </div>
      </div>

      <!-- ค้นหาเลข -->
      <div v-if="lotteryset.length > 0">
        <div class="text-center mt-2">
          <h4 class="text-primary">
            ผลการค้นหา
          </h4>
        </div>

        <div
          v-if="lotteryset.length > 0"
          class="row"
        >
          <div
            v-for="(src, index) in savedImages"
            id="disabled"
            :key="index"
            class="col-md-3 col-6 mb-md-2 mt-md-2 mb-50 mt-50 lotto-item"
          >
            <div class="-cart">
              <div v-if="addedIds.includes(src.lotteryData._id)">
                <div
                  style="
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: black;
                    opacity: 0.5;
                    z-index: 9;
                  "
                />
                <img
                  src="/images/box/added.png"
                  alt="cart-add"
                  class="ic-cart"
                >
              </div>

              <div class="sc-keVmGr csMRRn lotto-item">
                <div class="img-lott d-md-block d-none">
                  <img
                    :key="index"
                    :src="src.dataURL"
                    width="100%"
                  >
                </div>

                <div class="img-lott d-md-none d-block">
                  <img
                    :key="index"
                    width="100%"
                    :src="src.dataURL"
                  >
                </div>
              </div>

              <div class="d-flex">
                <b-form-select
                  v-model="src.lotteryData.status"
                  :options="filteredOptions(src.lotteryData.quantity_now)"
                  class="mr-50"
                  style="width: 35%"
                />
                <button
                  class="btn-add-cart"
                  style="width: 65%"
                  @click="addCart(src.lotteryData, src.lotteryData.status)"
                >
                  <i class="fal fa-cart-plus mr-50" /> เพิ่มลงตะกร้า
                </button>
              </div>
            </div>

            <!-- <div :class="{ 'card-add': addedIds.includes(src.lotteryData._id) }">
            <div class="img-lott d-md-block d-none">
              <img
                :key="index"
                :src="src.dataURL"
                width="100%"
              >
            </div>

            <div class="img-lott d-md-none d-block">
              <img
                :key="index"
                width="100%"
                :src="src.dataURL"
              >
            </div>

            <button
              class="btn-add-cart w-100"
              @click="addCart(src.lotteryData)"
            >
              <i class="fal fa-cart-plus mr-50" /> เพิ่มลงตะกร้า
            </button>
          </div> -->
          </div>
        </div>

        <LoadingHome v-if="Loadmore" />

        <div
          v-else-if="lotteryset.length == 0 && Loadmore == false"
          class="text-center my-4"
        >
          <img
            src="/icon/empty.png"
            alt="friend"
            height="75"
          >

          <p class="mb-0 text">
            สลากชุดนี้จำหน่ายแล้ว
          </p>
        </div>

        <div
          v-else
          class="mt-1 d-flex justify-content-center"
        >
          <u
            class="text-primary cursor-pointer"
            @click="show()"
          >
            ดูเลขค้นหาทั้งหมด
          </u>
          <!-- <b-pagination
          v-model="currentPage"
          class="mt-1"
          :per-page="perPage"
          hide-goto-end-buttons
          :total-rows="totalRows"
          first-number
          last-number
          align="center"
          @input="Search()"
        /> -->
        </div>
      </div>
      <!-- เลขเด็ด 3 ตัวหน้า -->
      <div v-if="lotteryset3f.length > 0">
        <div class="text-center mt-2">
          <h4 class="text-primary">
            เลขเด็ด 3 ตัวหน้าแนะนำ
          </h4>
        </div>

        <div
          v-if="lotteryset3f.length > 0"
          class="row"
        >
          <div
            v-for="(src, index) in savedImages3f"
            id="disabled"
            :key="index"
            class="col-md-3 col-6 mb-md-2 mt-md-2 mb-50 mt-50 lotto-item p-50"
          >
            <div class="-cart">
              <div v-if="addedIds.includes(src.lotteryData._id)">
                <div
                  style="
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: black;
                    opacity: 0.5;
                    z-index: 9;
                  "
                />
                <img
                  src="/images/box/added.png"
                  alt="cart-add"
                  class="ic-cart"
                >
              </div>

              <div class="sc-keVmGr csMRRn lotto-item">
                <div class="img-lott d-md-block d-none">
                  <img
                    :key="index"
                    :src="src.dataURL"
                    width="100%"
                  >
                </div>

                <div class="img-lott d-md-none d-block">
                  <img
                    :key="index"
                    width="100%"
                    :src="src.dataURL"
                  >
                </div>
              </div>
              <div class="d-flex">
                <b-form-select
                  v-model="src.lotteryData.status"
                  :options="filteredOptions(src.lotteryData.quantity_now)"
                  class="mr-50"
                  style="width: 35%"
                />
                <button
                  class="btn-add-cart"
                  style="width: 65%"
                  @click="addCart(src.lotteryData, src.lotteryData.status)"
                >
                  <i class="fal fa-cart-plus mr-50" /> เพิ่มลงตะกร้า
                </button>
              </div>
            </div>
          </div>
        </div>
        <LoadingHome v-if="Loadmore" />
        <div
          v-else
          class="mt-1 d-flex justify-content-center"
        >
          <u
            class="text-primary cursor-pointer"
            @click="show3f()"
          >
            ดูเลขทั้งหมด
          </u>
        </div>
      </div>
      <!-- เลขเด็ด 3 ตัวท้าย -->
      <div v-if="lotteryset3b.length > 0">
        <div class="text-center mt-2">
          <h4 class="text-primary">
            เลขเด็ด 3 ตัวท้ายแนะนำ
          </h4>
        </div>

        <div
          v-if="lotteryset3b.length > 0"
          class="row"
        >
          <div
            v-for="(src, index) in savedImages3b"
            id="disabled"
            :key="index"
            class="col-md-3 col-6 mb-md-2 mt-md-2 mb-50 mt-50 lotto-item p-50"
          >
            <div class="-cart">
              <div v-if="addedIds.includes(src.lotteryData._id)">
                <div
                  style="
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: black;
                    opacity: 0.5;
                    z-index: 9;
                  "
                />
                <img
                  src="/images/box/added.png"
                  alt="cart-add"
                  class="ic-cart"
                >
              </div>

              <div class="sc-keVmGr csMRRn lotto-item">
                <div class="img-lott d-md-block d-none">
                  <img
                    :key="index"
                    :src="src.dataURL"
                    width="100%"
                  >
                </div>

                <div class="img-lott d-md-none d-block">
                  <img
                    :key="index"
                    width="100%"
                    :src="src.dataURL"
                  >
                </div>
              </div>

              <div class="d-flex">
                <b-form-select
                  v-model="src.lotteryData.status"
                  :options="filteredOptions(src.lotteryData.quantity_now)"
                  class="mr-50"
                  style="width: 35%"
                />
                <button
                  class="btn-add-cart"
                  style="width: 65%"
                  @click="addCart(src.lotteryData, src.lotteryData.status)"
                >
                  <i class="fal fa-cart-plus mr-50" /> เพิ่มลงตะกร้า
                </button>
              </div>
            </div>
          </div>
        </div>
        <LoadingHome v-if="Loadmore" />
        <div
          v-else
          class="mt-1 d-flex justify-content-center"
        >
          <u
            class="text-primary cursor-pointer"
            @click="show3b()"
          >
            ดูเลขทั้งหมด
          </u>
        </div>
      </div>
      <!-- เลขเด็ด 2 ตัวท้าย -->
      <div v-if="lotteryset2b.length > 0">
        <div class="text-center mt-2">
          <h4 class="text-primary">
            เลขเด็ด 2 ตัวท้ายแนะนำ
          </h4>
        </div>

        <div
          v-if="lotteryset2b.length > 0"
          class="row"
        >
          <div
            v-for="(src, index) in savedImages2b"
            id="disabled"
            :key="index"
            class="col-md-3 col-6 mb-md-2 mt-md-2 mb-50 mt-50 lotto-item p-50"
          >
            <div class="-cart">
              <div v-if="addedIds.includes(src.lotteryData._id)">
                <div
                  style="
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: black;
                    opacity: 0.5;
                    z-index: 9;
                  "
                />
                <img
                  src="/images/box/added.png"
                  alt="cart-add"
                  class="ic-cart"
                >
              </div>

              <div class="sc-keVmGr csMRRn lotto-item">
                <div class="img-lott d-md-block d-none">
                  <img
                    :key="index"
                    :src="src.dataURL"
                    width="100%"
                  >
                </div>

                <div class="img-lott d-md-none d-block">
                  <img
                    :key="index"
                    width="100%"
                    :src="src.dataURL"
                  >
                </div>
              </div>

              <div class="d-flex">
                <b-form-select
                  v-model="src.lotteryData.status"
                  :options="filteredOptions(src.lotteryData.quantity_now)"
                  class="mr-50"
                  style="width: 35%"
                />
                <button
                  class="btn-add-cart"
                  style="width: 65%"
                  @click="addCart(src.lotteryData, src.lotteryData.status)"
                >
                  <i class="fal fa-cart-plus mr-50" /> เพิ่มลงตะกร้า
                </button>
              </div>
            </div>
          </div>
        </div>
        <LoadingHome v-if="Loadmore" />
        <div
          v-else
          class="mt-1 d-flex justify-content-center"
        >
          <u
            class="text-primary cursor-pointer"
            @click="show2b()"
          >
            ดูเลขทั้งหมด
          </u>
        </div>
      </div>
      <div class="text-center mt-2">
        <h4 class="text-primary">
          กล่องสุ่มเลข
        </h4>

        <div class="row justify-content-center">
          <div class="col-md-4 col-12">
            <img
              src="@/assets/images/banner/Banner3.png"
              alt="กล่องสุ่มเลข"
              class="cursor-pointer w-100"
              @click="box()"
            >
          </div>
        </div>
      </div>

      <!-- ไม่มีข้อมูล -->
      <!-- <div
        v-if="lotteryset3f.length == 0 && Loadmore == false"
        class="text-center my-4"
      >
        <img
          src="/icon/empty.png"
          alt="friend"
          height="75"
        >

        <p class="mb-0 text">
          สลากชุดนี้จำหน่ายแล้ว
        </p>
      </div> -->
      <br><br><br>

      <ThisFooter
        :added-ids="MyCarts"
        :countdown-time="countdownTime"
      />
    </div>
    <b-modal
      ref="my-welcome"
      hide-footer
      hide-header
      centered
    >
      <div class="text-center">
        <div class="closed">
          <i
            class="far fa-times cursor-pointer icon-close"
            @click="$refs['my-welcome'].hide()"
          />
        </div>

        <h4 class="mb-0 text-primary mt-2">
          กรุณาเข้าสู่ระบบเพื่อทำการสั่งซื้อ
        </h4>

        <button
          class="btn-gray-txt p-75 w-75 my-2"
          @click="$refs['my-welcome'].hide(), $router.push({ name: 'login' })"
        >
          ไปที่หน้าเข้าสู่ระบบ
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
// import ThisHeader from './components/ThisHeader.vue'
import ThisFooter from './components/ThisFooter.vue'
import OtpInput from './components/OtpInput.vue'
import LoadingHome from './components/LoadingHome.vue'
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'Home',
  metaInfo: {
    title: 'ซื้อสลาก',
    meta: [
      {
        name: 'สลากสุวรรณภูมิ | ซื้อสลากได้ที่นี่! สลากกินแบ่งรัฐบาลออนไลน์ มากกว่านรูปแบบออนไลน์ ได้ที่นี่',
        content:
          'สลากสุวรรณภูมิ จำหน่ายสลากกินแบ่งรัฐบาลออนไลน์ แบบถูกกฎหมาย ในรูปแบบออนไลน์ ได้ที่นี่ ที่เดียวมีเลขให้เลือก มากกว่า 3000000 เลข ติดต่อแอดมิน จำหน่ายตลอด 24 ชม.',
      },
    ],
  },
  components: {
    // ThisHeader,
    ThisFooter,
    OtpInput,
    // SkeletonLoaderVu,
    LoadingHome,
    BFormSelect,
    // BPagination,
  },
  data() {
    return {
      amountLotto: [
        { text: 'จำนวน', value: 0 },
        { text: '1 ใบ', value: 1 },
        { text: '2 ใบ', value: 2 },
        { text: '3 ใบ', value: 3 },
        { text: '4 ใบ', value: 4 },
        { text: '5 ใบ', value: 5 },
        { text: '6 ใบ', value: 6 },
        { text: '7 ใบ', value: 7 },
        { text: '8 ใบ', value: 8 },
        { text: '9 ใบ', value: 9 },
        { text: '10 ใบ', value: 10 },
      ],
      imgLotto: '/lotto/lotto.png',
      roundth: '',
      addedIds: [],
      MyCart: [],
      Loadmore: false,
      isLoadings: false,
      fullPage: true,
      numberInput: [],
      textOutput: [],
      renderComponent: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      isLoading: true,

      round: null,
      input_numlot: ['6', '6', '6', '6', '6', '6'],
      items: [],
      cartItems: [],
      MyCarts: [],
      countdownTime: 599,
      countdownInterval: null,
      lotteryset: [],
      lotteryset3f: [],
      lotteryset3b: [],
      lotteryset2b: [],

      savedImages: [],
      savedImages3f: [],
      savedImages3b: [],
      savedImages2b: [],

      textConfig: {
        Number: {
          x: 164,
          y: 0,
          fontSize: 18,
          color: '#000000',
          fontFamily: 'suvarnnabhumiNumber',
        },
        Text: {
          x: 164,
          y: 1,
          fontSize: 8,
          color: '#000000',
          fontFamily: 'suvarnnabhumiNumber',
        },
        'Date (TH)': {
          x: 50,
          y: 80,
          fontSize: 12,
          color: '#494949',
        },
        'Date (EN)': {
          x: 50,
          y: 120,
          fontSize: 11,
          color: '#ED5C85',
        },
        Round: {
          x: 50,
          y: 200,
          fontSize: 12,
          color: '#ED5C85',
        },
        Set: {
          x: 50,
          y: 250,
          fontSize: 12,
          color: '#000000',
        },
        Price: {
          x: 50,
          y: 300,
          fontSize: 18,
          color: '#ED5C85',
        },
      },
      userData: JSON.parse(localStorage.getItem('userData')),
      img: '',
    }
  },
  computed: {

  },
  created() {
    if (this.userData) {
      const storedItems = localStorage.getItem('MyCart')
      if (storedItems) {
        this.MyCart = JSON.parse(storedItems)
      }
    }
    // this.$http.get('lotto-suvarn/GetRound').then(ress => {
    //   localStorage.setItem('imgLotto', JSON.stringify(ress.data.img))
    //   // console.log(this.img)
    // })

    const imgLotto = JSON.parse(localStorage.getItem('imgLotto'))

    if (imgLotto) {
      this.img = imgLotto
    }
    // if (!this.userData) {
    //   this.$router.push({ name: 'home' })
    // }
  },
  async mounted() {
    if (!localStorage.getItem('MyCart')) {
      localStorage.setItem('MyCart', JSON.stringify([]))
    }
    // await this.GetRound()
    this.MyCarts = JSON.parse(localStorage.getItem('MyCart'))
    localStorage.removeItem('added')
    // localStorage.removeItem('otp')
    // localStorage.removeItem('type')
    if (localStorage.getItem('otp') && localStorage.getItem('type')) {
      this.generateImages()
    }
    this.generateImages3f()
    this.generateImages3b()
    this.generateImages2b()
    this.$nextTick(() => {
      this.$refs.otpInput.$el.querySelector('input').blur()
    })
  },
  methods: {
    filteredOptions(count) {
      // console.log(count)
      return this.amountLotto.slice(0, count)
    },
    show() {
      this.currentPage += 1
      this.totalRows += 1
      this.perPage += 8
      this.generateImages()
    },
    show3f() {
      this.currentPage += 1
      this.totalRows += 1
      this.perPage += 8
      this.generateImages3f()
    },
    show3b() {
      this.currentPage += 1
      this.totalRows += 1
      this.perPage += 8
      this.generateImages3b()
    },
    show2b() {
      this.currentPage += 1
      this.totalRows += 1
      this.perPage += 8
      this.generateImages2b()
    },
    convertNumberToText(number) {
      switch (number) {
        case 1:
          return 'ONE'
        case 2:
          return 'TWO'
        case 3:
          return 'THR'
        case 4:
          return 'FOR'
        case 5:
          return 'FIV'
        case 6:
          return 'SIX'
        case 7:
          return 'SEV'
        case 8:
          return 'EGT'
        case 9:
          return 'NIN'
        case 0:
          return 'ZER'
        default:
          return 'EMT'
      }
    },
    cleartext() {
      this.input_numlot = ['', '', '', '', '', '']
      localStorage.removeItem('otp')
      localStorage.removeItem('type')
      // OtpInput Component
      this.renderComponent = false
      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true
      })
      // this.savedImages = []
      // this.generateImages()
    },
    generateImages() {
      this.Loadmore = true
      this.isLoading = true
      const type = JSON.parse(localStorage.getItem('type'))
      const num = localStorage.getItem('otp')
      if (type && num) {
        const Obj = {
          page: this.currentPage,
          lottotype: type,
          lottonumber: num,
          uid: this.userData.userID,
        }
        this.$http
          .post('/lotto-suvarn/list', Obj)
          .then(response => {
            if (response.data.lottery.length > 0) {
              this.lotteryset = response.data.lottery
              this.roundth = response.data.lottery[0].roundth
              this.totalRows = response.data.total
              this.lotteryset.forEach(async lotteryData => {
                // eslint-disable-next-line no-param-reassign
                lotteryData.lottonumber = await Array.from(
                  lotteryData.lottonumber,
                ).map(Number)
                const dataURL = await this.drawCanvas(lotteryData)
                this.savedImages.push({ dataURL, lotteryData })
                // console.log(this.savedImages)
                this.isLoading = false
              })
            }
            // console.log(this.lotteryset)
            this.Loadmore = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false
            this.Loadmore = false
          })
      } else {
        this.isLoading = false
        this.Loadmore = false
      }
    },
    generateImages3f() {
      this.Loadmore = true
      this.isLoading = true
      const type = JSON.parse(localStorage.getItem('type'))
      const num = localStorage.getItem('otp')
      const Obj = {
        page: this.currentPage,
        lottotype: type,
        lottonumber: num,
        uid: this.userData.userID,
      }
      this.$http
        .post('/lotto-suvarn/luckylist/3f', Obj)
        .then(response => {
          if (response.data.lottery.length > 0) {
            this.lotteryset3f = response.data.lottery
            this.roundth = response.data.lottery[0].roundth
            this.totalRows = response.data.total
            this.lotteryset3f.forEach(async lotteryData => {
              // eslint-disable-next-line no-param-reassign
              lotteryData.lottonumber = await Array.from(
                lotteryData.lottonumber,
              ).map(Number)
              const dataURL = await this.drawCanvas(lotteryData)
              this.savedImages3f.push({ dataURL, lotteryData })
              this.isLoading = false
            })
          }
          this.Loadmore = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
          this.Loadmore = false
        })
    },
    generateImages3b() {
      this.Loadmore = true
      this.isLoading = true
      const type = JSON.parse(localStorage.getItem('type'))
      const num = localStorage.getItem('otp')
      const Obj = {
        page: this.currentPage,
        lottotype: type,
        lottonumber: num,
        uid: this.userData.userID,
      }
      this.$http
        .post('/lotto-suvarn/luckylist/3b', Obj)
        .then(response => {
          if (response.data.lottery.length > 0) {
            this.lotteryset3b = response.data.lottery
            this.roundth = response.data.lottery[0].roundth
            this.totalRows = response.data.total
            this.lotteryset3b.forEach(async lotteryData => {
              // eslint-disable-next-line no-param-reassign
              lotteryData.lottonumber = await Array.from(
                lotteryData.lottonumber,
              ).map(Number)
              const dataURL = await this.drawCanvas(lotteryData)
              this.savedImages3b.push({ dataURL, lotteryData })
              this.isLoading = false
            })
          }
          this.Loadmore = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
          this.Loadmore = false
        })
    },
    generateImages2b() {
      this.Loadmore = true
      this.isLoading = true
      const type = JSON.parse(localStorage.getItem('type'))
      const num = localStorage.getItem('otp')
      const Obj = {
        page: this.currentPage,
        lottotype: type,
        lottonumber: num,
        uid: this.userData.userID,
      }
      this.$http
        .post('/lotto-suvarn/luckylist/2b', Obj)
        .then(response => {
          // console.log(response.data.lottery)
          if (response.data.lottery.length > 0) {
            this.lotteryset2b = response.data.lottery
            this.roundth = response.data.lottery[0].roundth
            this.totalRows = response.data.total
            this.lotteryset2b.forEach(async lotteryData => {
              // eslint-disable-next-line no-param-reassign
              lotteryData.lottonumber = await Array.from(
                lotteryData.lottonumber,
              ).map(Number)
              const dataURL = await this.drawCanvas(lotteryData)
              this.savedImages2b.push({ dataURL, lotteryData })
              this.isLoading = false
            })
          }
          // console.log(this.lotteryset2b)
          this.Loadmore = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
          this.Loadmore = false
        })
    },
    async drawCanvas(lotteryData) {
      const canvas = document.createElement('canvas')
      canvas.width = 299
      canvas.height = 149.72
      const ctx = canvas.getContext('2d')

      const img = new Image()
      img.src = await this.img
      // console.log(lotteryData)
      // eslint-disable-next-line no-return-assign
      await new Promise(resolve => (img.onload = resolve))
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
      // console.log('canvas')
      // const rowSpacing = 50
      const colSpacing = 16.5

      const startX = 159
      const startY = 28

      const startX2 = 159
      const startY2 = 36

      lotteryData.lottonumber.forEach((num, index) => {
        this.addText(
          ctx,
          'Number',
          num,
          false,
          startX + index * colSpacing,
          startY,
        )
      })

      lotteryData.lottonumber.forEach((num, index) => {
        const numText = this.convertNumberToText(num)
        this.addText(
          ctx,
          'Text',
          numText,
          false,
          startX2 + index * colSpacing,
          startY2,
        )
      })
      const dataURL = canvas.toDataURL()
      return dataURL
    },
    addText(
      ctx,
      label,
      text,
      includeLabel = true,
      x,
      y,
      fontStyle = 'normal',
      fontWeight = 'normal',
    ) {
      const config = this.textConfig[label]
      // eslint-disable-next-line no-param-reassign
      ctx.fillStyle = config.color
      // eslint-disable-next-line no-param-reassign
      ctx.font = `${fontStyle} ${fontWeight} ${config.fontSize}px ${config.fontFamily}`
      ctx.fillText(includeLabel ? `${label}: ${text}` : `${text}`, x, y)
    },
    box() {
      if (this.userData) {
        this.$router.push({ name: 'lucky-box' })
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    Search() {
      this.savedImages = []
      this.generateImages()
    },
    clearSearch() {
      this.otpData = ['', '', '', '', '', '']
    },
    addCart(lotto, count) {
      // console.log(lotto, count)
      if (count <= 0) {
        this.SwalError('กรุณาเลือกจำนวนใบที่ต้องการซื้อ')
      } else if (localStorage.getItem('userData')) {
        // eslint-disable-next-line no-underscore-dangle
        this.addedIds.push(lotto._id)

        const addedArray = this.addedIds.map(id => ({ _id: id }))
        localStorage.setItem('added', JSON.stringify(addedArray))
        // console.log(this.added)
        const type = localStorage.getItem('type')

        // console.log(type)
        if (type === '"num6"') {
          // console.log('เข้า')
          localStorage.removeItem('otp')
          localStorage.removeItem('type')
        }
        const Obj = {
          // eslint-disable-next-line no-underscore-dangle
          lotto_id: lotto._id,
          count,
        }
        this.$http
          .post('/lotto-suvarn/AddToCart', Obj)
          .then(res => {
            // console.log(res)
            // this.savedImages = []
            // eslint-disable-next-line no-param-reassign
            lotto.lottonumber = lotto.num6
            // eslint-disable-next-line no-param-reassign
            lotto.updated_at = new Date()
            const s = JSON.parse(localStorage.getItem('MyCart'))
            s.push(res.data)
            localStorage.setItem('MyCart', JSON.stringify(s))
            this.MyCarts = JSON.parse(localStorage.getItem('MyCart'))
            // this.generateImages()
            // this.Success('เพิ่มสลากลงตะกร้าสำเร็จ')
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$refs['my-welcome'].show()
        // this.SwalError('กรุณา เข้าสู่ระบบก่อนทำรายการ')
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ลงตะกร้าสำเร็จ</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    startCountdown() {
      this.countdownInterval = setInterval(() => {
        this.countdownTime -= 1
        if (this.countdownTime === 0) {
          clearInterval(this.countdownInterval)
        }
      }, 1000)
    },
    beforeDestroy() {
      clearInterval(this.countdownInterval)
    },
  },
}
</script>

<style>
@font-face {
  font-family: suvarnnabhumiNumber !important;
  src: url(/font/Suvarnnabhumi_number-Regular.ttf) format("truetype") !important;
}
.text2 {
  font-size: 1.5em;
  font-weight: 900;
  background-image: linear-gradient(
    to left,
    #f2cc8f 0.23%,
    #fffdf6 49.3%,
    #ab925d 99.84%
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
}
.custom-select {
    padding: 0.438rem 2rem 0.5rem 0.6rem !important;
}
</style>
