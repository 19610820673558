<template>
  <div>
    <div class="row">
      <div
        v-for="(item, index) in 8"
        :key="index"
        class="col-lg-3 col-md-4 col-6 mb-md-2 mt-md-2 mb-50 mt-50 lotto-item"
      >

        <!-- Desktop only -->
        <div class="d-none d-md-block">
          <div class="mb-50">
            <skeleton-loader-vue
              type="rounded"
              rounded
              :width="299"
              :height="149.72"
              animation="wave"
            />
          </div>

          <skeleton-loader-vue
            type="rounded"
            rounded
            :width="299"
            :height="41.61"
            animation="wave"
          />
        </div>

        <!-- Mobile only -->
        <div class="d-block d-md-none">
          <div class="mb-50">
            <skeleton-loader-vue
              type="rounded"
              rounded
              :width="167"
              :height="83.22"
              animation="wave"
            />
          </div>

          <skeleton-loader-vue
            type="rounded"
            rounded
            :width="167"
            :height="34.61"
            animation="wave"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import SkeletonLoaderVue from 'skeleton-loader-vue'

export default {
  components: {
    SkeletonLoaderVue,
  },
  computed: {
    dynamicWidth() {
      return this.$el.parentElement.offsetWidth
    },
  },

}
</script>

<style>
/* .skeleton-loader-vue {
  width: 100% !important;
} */

</style>
